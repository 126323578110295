export const isVerifiedOTPAction = (response) => {
  return {
    type: "isVerifiedOTP",
    payLoad: response,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};