import { combineReducers } from "redux";

let tokenInitialState = {
    token: "",
};
const saveTokenReducer = (state = tokenInitialState, action) => {
    switch (action.type) {
        case "saveAccessToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};


let userDataInitialState = {
    userData: {
        email: "",
        isAlreadyRegistered: 0,
        otp: "",
    },
};
const storeUserDetailsReducer = (
    state = userDataInitialState,
    { type, payLoad }
) => {
    switch (type) {
        case "storeUserData": {
            return {
                userData: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
};

let isVerifiedOTPInitialState = false;
const isVerifiedOTPReducer = (state = isVerifiedOTPInitialState, action) => {
    switch (action.type) {
        case "isVerifiedOTP": {
            return action.payLoad;
        }
        default: {
            return state;
        }
    }
};

export const masterReducer = combineReducers({
    saveTokenReducer: saveTokenReducer,
    storeUserDetailsReducer: storeUserDetailsReducer,
    isVerifiedOTPReducer: isVerifiedOTPReducer,
});
