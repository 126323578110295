import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import "../common/styles/HiringStaticPage.css";
const theme = createMuiTheme({
    overrides: {
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            }
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
                width: '100%'
            },
        },
        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC"
                }
            }
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68"
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {

                    backgroundColor: "#CCF5F5",
                }
            }
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #002949",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2"
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiFilledInput: {
            root: {
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&$hover": {
                    backgroundColor: "#E8F8F8"
                },
                "&$disabled": {
                    "pointerEvent": "none",
                    "color": "#707080",
                    "backgroundColor": "#001035",
                    "border": "2px solid #707070",
                    "borderRadius": "0",
                    "&:before": {
                        "borderBottom": "1px solid #001035",
                    },
                    "&:hover:before": {
                        "borderBottom": "1px solid #001035",
                        "borderBottomStyle": "solid"
                    }
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiFormControl: {
            root: {
                color: "#FFFFFF",
                width: "100",
                paddingLeft: "0px"
            }
        },
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5"
            }
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            }
        },
        MuiPaper: {
            rounded: {
                border: `2px solid #2EE6E2`,
                marginBottom: "20px",
                background: "#CCF5F5"
            }
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none"
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`
                }
            },
            menuItem: {
                color: "#FFFFFF"
            },
            selectIcon: {
                color: "#00CCCC"
            },
            input: {
                backgroundColor: "#000028"
            }
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
                "& div:hover": {
                    color: "#00CCCC"
                }
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            }
        },
        //MuiTablePagination: {
        //    root: {
        //        borderBottom: "none"
        //    },
        //    toolbar: {
        //        borderTop: `1px solid #FFFFFF`,
        //        "& MuiTypography-root": {
        //            color: `#00CCCC !important`
        //        }
        //    },
        //    menuItem: {
        //        color: "#FFFFFF"
        //    },
        //    selectIcon: {
        //        color: "#00CCCC"
        //    },
        //    input: {
        //        backgroundColor: "#000028"
        //    }
        //},
        //MuiTypography: {
        //    caption: {
        //        color: "#FFFFFF"
        //    }
        //},
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important"
            },
            body: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        //MuiFormControl: {
        //    root: {
        //        paddingLeft: "0px"
        //    }
        //},
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#FFFFFF"
            },
            container: {
                background: ""
            }
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF"
            },
            switchHeader: {
                color: "#FFFFFF"
            },
            daysHeader: {
                color: "#FFFFFF"
            },
            dayLabel: {
                color: "#FFFFFF"
            }
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF"
            }
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: ""
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF"
            }
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028"
            }
        },
        MuiAlert: {
            root: {
                margin: "0 50px -10px !important"
            }
        },
        MuiFormHelperText: {
            contained: {
                paddingTop: "10px",
                backgroundColor: "white",
                marginBottom: "-10px !important",
                marginLeft: "0px !important",
                marginRight: "0px !important",
                color: "red !important",
                lineHeight: "0.5 !important"
            },
        },
    }
});

export default function OTPpage(props) {
    const [otp, setOTP] = useState('');
    const [otpAlertText, setOtpAlertText] = useState('');

    const [open, setOpen] = useState(false);

    const changeEventOTP = (e) => {
        setOTP(e.target.value);
        props.otpChangeEvent(e.target.value);
    }

    const verifyOTP = () => {
        props.otpClickVerify();
        setOtpAlertText(props.otpDetails[0].otpAlertText);
    }

    const resendOTP = () => {
        props.otpClickResend(props.loginDetails);
    }

    const [alert, setAlert] = useState(false);
    const handleAlertClose = (e) => {
        setAlert(false);
    }

    //function LoginWithOTP(loginData) {
    //    const options = {
    //        method: 'post',
    //        headers: {
    //            'Accept': 'application/json, text/plain, */*',
    //            'Content-Type': 'application/json'
    //        },
    //        body: loginData
    //    }
    //    fetch('Login/LoginWithOTP/', options)
    //        .then(response => response.json())
    //        .then(data => {
    //            if (data) {
    //                if (data.roleId == 1) {
    //                    window.location.href = '/Home';
    //                }
    //                else if (data.roleId == 2) {
    //                    window.location.href = '/Home';
    //                }
    //                else if (data.roleId == 3) {
    //                    window.location.href = '/Home';
    //                }
    //                else {
    //                    alert("Login Failed.");
    //                }
    //            }
    //        }).catch(error => console.log("Error:", error));
    //}

    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            props.otpClickVerify();
            setOtpAlertText(props.otpDetails[0].otpAlertText);
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', enterKeyDown);
        props.loader === true ? setOpen(true) : setOpen(false);
        (props.otpDetails[0].otpSent != '' && props.otpDetails[0].otpSent != undefined && props.otpDetails[0].otpSent != null && props.otpDetails[0].otpSent)
            ?
            setAlert(true) : setAlert(false);

        return () => {
            document.removeEventListener('keydown', enterKeyDown);
        }
    }, [props.loader, props.otpDetails]);

    return (
        <ThemeProvider theme={theme}>
            <div className="master-layout-block d-flex jc-center otp-padding">
                <Grid xs={12} sm={12} md={6} lg={6} className="d-flex fd-column bdr-radius box-shadow ">
                    <Typography className="mt-1 otpheadingtext pd-mobile-10" component="h1" variant="h5">
                        <b> Siemens Technical Academy India </b>
                    </Typography>
                    <Typography className="mt-1 otpheadingtext" component="h1" variant="h5">
                        <b> Student Admission Form </b>
                    </Typography>

                    <h4 className="mt-2 d-flex jc-center">Confirm OTP</h4>
                    <React.Fragment className="d-flex jc-center">
                        {alert &&
                            <Alert severity="success" onClose={handleAlertClose} style={{ margin: "0 50px -10px 50px !important" }}>
                                OTP has been successfully sent to your email address.
                            </Alert>
                        }
                    </React.Fragment>
                    <p className="f-16 clr-dark-blue d-flex jc-center">Please enter OTP sent to your email.</p>
                    <form className="d-flex jc-center">
                        <Grid item xs={10} sm={8} className="d-flex jc-center">
                            <TextField className="lightInputFields mt-1"
                                id="otp"
                                label="OTP"
                                variant="filled"
                                name="OTP"
                                onChange={changeEventOTP}
                                helperText={otpAlertText}
                            />
                        </Grid>
                    </form>
                    <div className="d-flex mb-3 px-2 jc-center">
                        <Button xs={5} sm={4} className="ns-btn-primary mt-2 " value={"Verify"} onClick={verifyOTP}>
                            Verify
                        </Button>
                        <Button xs={5} sm={4} className="ns-btn-primary mt-2 ml-2" value={"Resend Otp"} onClick={resendOTP}>
                            Resend Otp
                        </Button>
                    </div>
                    <Dialog
                        open={open}
                        //onClose={handleClose}
                        aria-labelledby="draggable-dialog-title"
                        id="templateUploadSuccess"
                        PaperProps={{
                            style: {
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                borderColor: "transparent"
                            },
                        }}
                    >
                        <DialogContent>
                            <CircularProgress />
                        </DialogContent>
                    </Dialog>

                </Grid>
            </div>
        </ThemeProvider>
    );
}

